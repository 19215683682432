module directives {
    export module applicationcore {

        interface IMenuToggleItemScope extends ng.IScope {
            item: interfaces.applicationcore.IMenu,
            isCollapsed: boolean,
            toggleItem(): void
        }

        export class menuToggleItemDirective implements ng.IDirective {
            scope = {
                item: "=",
                menuToggle: "&"
            }

            templateUrl = 'templates/modules/applicationcore/menuToggleItemView.html';
            restrict = 'E';
            transclude = true;

            constructor(private $timeout: ng.ITimeoutService) {
            }

            link = ($scope: IMenuToggleItemScope, $element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
                $scope.isCollapsed = true;

                $scope.toggleItem = () => {
                    $scope.$root.$broadcast("MenuOpen", $scope.item);
                    $scope.isCollapsed = !$scope.isCollapsed;
                };

                $scope.$root.$on("MenuOpen", (event, args: interfaces.applicationcore.IMenu) => {
                    if (args.name !== $scope.item.name) {
                        $scope.isCollapsed = true;
                    }
                });
            };

            static factory(): ng.IDirectiveFactory {
                const directive = ($timeout) => new menuToggleItemDirective($timeout);
                directive.$inject = ['$timeout'];

                return directive;
            }
        }

    }

    angular.module("app").directive("menuToggle", directives.applicationcore.menuToggleItemDirective.factory());
}